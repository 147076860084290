import * as React from "react";
import { useState, useEffect } from "react";
import "./tableComponent.css";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import parser from "html-react-parser";
import {
  IconButton,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

import DragHandleIcon from "@material-ui/icons/DragHandle"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface Data {
  id: number;
  activated: string;
  name: string;
  number: number;
  email: string;
}
interface StyleArray {
  columnName: string;
  style: React.CSSProperties;
}
type TableComponentPropType = {
  handleDeleteClick?: (item: Data) => void;
  handleEditClick?: (item: Data) => void;
  handleVisibilityClick?: (item: Data) => void;
  handleClick?: (item: Data, index: number) => void;
  handlePushClick?: (item: Data) => void;
  handleDownloadClick?: (value: string) => void;
  handlePublishedClick?: (id: number, value: boolean) => void;
  handleCustomerOnClick?: (id: number) => void;
  handleReadMoreClick?: (id: number) => void;
  getCheckedItems?: (items: Data[]) => void;
  data: Data[];
  titles: Array<string>;
  containerClassname?: React.CSSProperties;
  tableContainerClassname?: React.CSSProperties;
  headCellStyle?: React.CSSProperties;
  bodyCellStyle?: React.CSSProperties;
  activatedCellStyleTrue?: React.CSSProperties;
  activatedCellStyleFalse?: React.CSSProperties;
  permissionCellStyle?: React.CSSProperties;
  variantCellStyle?: React.CSSProperties;
  descriptionContainerStyle?: React.CSSProperties;
  pushButtonStyle?: React.CSSProperties;
  placedStatusCellStyle?: React.CSSProperties;
  deliveredStatusCellStyle?: React.CSSProperties;
  refundedStatusCellStyle?: React.CSSProperties;
  cancelledStatusCellStyle?: React.CSSProperties;
  processingStatusCellStyle?: React.CSSProperties;
  customerCellStyle?: React.CSSProperties;
  customerCellStyleWithoutClick?: React.CSSProperties;
  customisedTypeCellStyle?: React.CSSProperties;
  originalTypeCellStyle?: React.CSSProperties;
  draftStatusCellStyle?: React.CSSProperties;
  activeStatusCellStyle?: React.CSSProperties;
  deleteRecord?: boolean;
  edit?: boolean;
  visibility?: boolean;
  activeStatus?: boolean,
  push?: boolean;
  checkbox?: boolean;
  isCheckboxPositionedRightSide?: boolean;
  showIds?: boolean;
  handleTableActive?: any,
  editIconAlign?:
  | "left"
  | "right"
  | "inherit"
  | "center"
  | "justify"
  editIconSize?: "medium" | "small";
  pushButtonSize?: "large" | "medium" | "small";
  pushButtonAlign?:
  | "left"
  | "right"
  | "inherit"
  | "center"
  | "justify";
  styleByIndex?: StyleArray[];
  downloadIconSize?: "medium" | "small";
  imageStyle?: React.CSSProperties;
  imageBorderRadius?: string | number;
  bodyCellVerticalAlign?: string;
  bodyCellPadding?: string | number;
  ignoredKeys?: string[];
  draggable?: boolean;
  setData?: (data: any) => void;
}


export const TableComponent = (props: TableComponentPropType) => {
  const {
    titles,
    data,
    containerClassname,
    tableContainerClassname,
    handleDeleteClick,
    deleteRecord,
    handleEditClick,
    handleVisibilityClick,
    handleReadMoreClick,
    handleClick,
    edit,
    visibility,
    activeStatus,
    editIconAlign,
    bodyCellStyle: _bodyCellStyle,
    headCellStyle,
    editIconSize,
    activatedCellStyleTrue,
    activatedCellStyleFalse,
    styleByIndex,
    permissionCellStyle,
    variantCellStyle,
    descriptionContainerStyle,
    push,
    pushButtonAlign,
    pushButtonSize,
    pushButtonStyle,
    handlePushClick,
    placedStatusCellStyle,
    deliveredStatusCellStyle,
    refundedStatusCellStyle,
    cancelledStatusCellStyle,
    processingStatusCellStyle,
    customerCellStyle,
    customerCellStyleWithoutClick,
    customisedTypeCellStyle,
    originalTypeCellStyle,
    draftStatusCellStyle,
    activeStatusCellStyle,
    downloadIconSize,
    handleDownloadClick,
    handlePublishedClick,
    handleCustomerOnClick,
    checkbox,
    isCheckboxPositionedRightSide,
    getCheckedItems = () => { },
    showIds,
    imageStyle: _imageStyle,
    imageBorderRadius,
    bodyCellVerticalAlign,
    bodyCellPadding,
    handleTableActive,
    ignoredKeys = [],
    draggable = false,
    setData,
  } = props;

  const bodyCellStyle = {
    ..._bodyCellStyle,
    verticalAlign: bodyCellVerticalAlign ?? _bodyCellStyle?.verticalAlign,
    padding: bodyCellPadding || _bodyCellStyle?.padding
  };

  const imageStyle = {
    ..._imageStyle,
    borderRadius: imageBorderRadius ?? _imageStyle?.borderRadius
  };

  const classes = useStyles();

  const [selected, setSelected] = useState<Data[]>([]);
  useEffect(() => {
    props.getCheckedItems?.([]);
    setSelected([]);
  }, [data])
  const onSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newSelecteds = data.map((item) => item);
      setSelected(newSelecteds);
      getCheckedItems(newSelecteds);
      return;
    }
    getCheckedItems([]);
    setSelected([]);
  };
  const onSelectItemClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: Data
  ) => {
    const selectedIndex = selected.indexOf(item);
    let newSelected: Data[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    getCheckedItems(newSelected);
    setSelected(newSelected);
  };
  const isSelected = (item: Data) => selected.indexOf(item) !== -1;
  const pad = (n: number) => {
    return n < 10 ? "0" + n : n;
  };
  const renderDataCells = (item: any, key: any) => {
    switch (key) {
      case "id":
        return pad(item[key]).toString();
      case "activate":
        switch (item[key]) {
          case true:
            return (
              <FormControl fullWidth>
                <Select
                  defaultValue={item[key]}
                  disableUnderline={true}
                  className="statusSelect"
                  style={item.activate ? activatedCellStyleTrue : activatedCellStyleFalse}
                  onChange={(e) => handleTableActive(e, item)}
                  value={item.activate}

                >
                  <MenuItem value={"true"}>Activated</MenuItem>
                  <MenuItem value={"false"}>Deactivated</MenuItem>
                </Select>
              </FormControl>
            );
          case false:
            return (
              <FormControl fullWidth>
                <Select
                  defaultValue={item[key]}
                  disableUnderline={true}
                  className="statusSelect"
                  style={!item.activate ? activatedCellStyleFalse : activatedCellStyleTrue}
                  onChange={(e) => handleTableActive(e, item)}
                  label={!item.activate ? 'DeActivated' : 'Activated'}
                  value={item.activate}

                >
                  <MenuItem value={"true"}>Activated</MenuItem>
                  <MenuItem value={"false"}>Deactivated</MenuItem>
                </Select>
              </FormControl>
            );

            return (
              <div style={cancelledStatusCellStyle} className="borderedCell">
                {item[key]}
              </div>
            );
          default:
            return (
              <div
                style={
                  !!item[key] ? activatedCellStyleTrue : activatedCellStyleFalse
                }
                className="borderedCell"
              >
                {!!item[key] ? "Activated" : "Deactivated"}
              </div>
            );
            break;
        }

      case "customer":
        return (
          <div
            onClick={() => handleCustomerOnClick?.(item[key]?.id)}
            style={
              !!handleCustomerOnClick
                ? customerCellStyle
                : customerCellStyleWithoutClick
            }
          >
            {item[key]?.name}
          </div>
        );
      case "description":
        const htmlDoc = new DOMParser().parseFromString(item[key].content  ?? item[key] ?? "", "text/html")
        const description = htmlDoc.body.textContent ?? "";
        let content = item[key].content ?? item[key] ?? "";
        let parsed;
        typeof content === "string" && (parsed = parser(content))
        const readMore = item.description.read_more || item.read_more;
        return (
          <div
            className="table-component-description-container"
            style={descriptionContainerStyle}
          >
            {description.length > 100 ? (
                <>
                  {readMore ? parsed : `${description.substring(0, 100)}... `}
                  <span
                    style={{ cursor: "pointer", color: readMore ? "red" : "blue" }}
                    onClick={() => handleReadMoreClick?.(item.id)}
                  >
                    {readMore ? "Read Less" : "Read More"}
                  </span>
                </>
              ) : parsed
            }
          </div>
        );

      case "download":
        return (
          <IconButton
            className="icon-download"
            aria-label="download"
            size={downloadIconSize}
            onClick={() => handleDownloadClick?.(item[key])}
          />
        );

      case "permissions":
        return item[key]?.map((permission: string) => (
          <div style={permissionCellStyle} className="borderedCell">
            {permission}
          </div>
        ));
      case "custom":
        return item[key]?.component;
      case "bulk-upload-product":
        return (
          <div key={item[key]?.image + item[key]?.name} className="bulk-uploads__table-row__main-container">
            <img src={item[key]?.image} className="bulk-uploads__table-row__image" />
            <div className="bulk-uploads__table-row__labels-box">
              <label className="bulk-uploads__table-row__top-label">{item[key]?.name}</label>
              <label className="bulk-uploads__table-row__bottom-label">
                {window.localStorage.getItem("currency_type")}{" "}
                {item[key]?.price}
              </label>
            </div>
          </div>
        );
      case "activated":
        return (
          <div
            style={
              !!item[key]?.status || !!item[key] ? activatedCellStyleTrue : activatedCellStyleFalse
            }
            className="borderedCell"
          >
            {!!item[key]?.status || !!item[key] ? "Active" : "Inactive"}
          </div>
        );
      case "published":
        return (
          <FormControl fullWidth>
            <Select
              value= {!!item[key]?.status || !!item[key] ? "Yes" : "No"}
              disableUnderline={true}
              className="statusSelect"
              style={
                !!item[key]?.status || !!item[key] ? activatedCellStyleTrue : activatedCellStyleFalse
              }
              onChange={(e) =>
                e.target.value === "Yes"
                ? handlePublishedClick?.(item.id, true)
                : handlePublishedClick?.(item.id, false)
              }>
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
            </Select>
          </FormControl>
        );
      case "status":
        switch (item[key]?.status || item[key]) {
          case "draft":
            return (
              <FormControl fullWidth>
                <Select
                  value={item[key]?.status || item[key]}
                  disableUnderline={true}
                  className="statusSelect"
                  style={draftStatusCellStyle}
                  onChange={(e) => item[key]?.onSelect(e?.target?.value, item)}
                >
                  <MenuItem value={"draft"}>Draft</MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                </Select>
              </FormControl>
            );
          case "active":
            return (
              <FormControl fullWidth>
                <Select
                  value={item[key]?.status || item[key]}
                  disableUnderline={true}
                  className="statusSelect"
                  style={activeStatusCellStyle}
                  onChange={(e) => item[key]?.onSelect(e?.target?.value, item)}
                >
                  <MenuItem value={"draft"}>Draft</MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                </Select>
              </FormControl>
            );
          case "placed":
          case 'confirmed':
            case 'returned':
              case 'delivered':
                case 'refunded':
                  case 'cancelled':
                    case 'in_transit':
                      case 'processing':

            return (
              <div style={getStatusStyle(item[key]?.status || item[key])} className="borderedCell">
                {(item[key]?.status || item[key]) === 'in_transit' ? 'In Transit' : camalize(item[key]?.status || item[key])}
              </div>
            );

          default:
            return (
              <div
                style={
                  !!item[key]?.status || !!item[key] ? activatedCellStyleTrue : activatedCellStyleFalse
                }
                className="borderedCell"
              >
                {!!item[key]?.status || !!item[key] ? "Active" : "Draft"}
              </div>
            );
            break;
        }

      case "type":
        switch (item[key]) {
          case "Customised":
            return (
              <div style={customisedTypeCellStyle} className="borderedCell">
                {item[key]}
              </div>
            );
          case "Original":
            return (
              <div style={originalTypeCellStyle} className="borderedCell">
                {item[key]}
              </div>
            );
          default:
            break;
        }
      case "image":
        return <img src={item[key]} style={imageStyle} />;

      case "category":
        return (
          <div style={{ marginRight: "40px" }}>
            {item[key]?.name}
          </div>
        );

      case "subCategory":
        return (
          <div>
            {item[key]?.sub_categories?.[0]?.name}
          </div>
        );

      case "subCategoryProduct":
        return (
          <div>
            {item?.subCategoryProduct?.map((subCategory: string) => (
              <span key={subCategory}>
                {subCategory}
                <br />
              </span>
              ))}
          </div>
        );
      case "price":
        return (
          <div style={{ marginRight: "25px" }}>
            {window.localStorage.getItem("currency_type") || ""}{item[key]}
          </div>
        );

      case "imageAndName":
        return (
          <div style={{ display: "flex", gap: 16, paddingLeft: 24 }}>
            <img src={item[key]?.image} style={imageStyle} />
            <span>{item[key]?.name}</span>
          </div>
        );
      case "variant_properties":
        return item[key]?.map((variant: any) => (
          <Chip
            style={variantCellStyle}
            label={variant?.name}
            onDelete={() => {
              const event = new CustomEvent("deleteVariantProperty", {
                detail: {
                  variantId: variant.variant_id,
                  variantPropertyId: variant.variant_property_id,
                },
              });
              document.dispatchEvent(event);
            }}
          />
        ));
      default:
        // Limit the visual length of the description to 30 characters
        return (item[key]?.length > 30 ? item[key]?.substring(0, 30) + '...' : item[key])?.toString()
    }
  };
  const getStatusStyle = (status:string) =>{
    switch(status){
      case "placed":
        return placedStatusCellStyle;
        case 'confirmed':

            case 'delivered':
              return deliveredStatusCellStyle;
              case 'refunded':
                return refundedStatusCellStyle;
              case 'cancelled':
                return cancelledStatusCellStyle;
                  case 'processing':
                    return processingStatusCellStyle;
                  default:
                    return cancelledStatusCellStyle;
    }
  }
  const camalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderRows = (data: any) => {
    return (
      !!data &&
      data.map((item: any, index: number) => (
        <TableRow  
        key={item.id+'_'+index}
          component={draggable ? DraggableComponent(item.id, index) : "tr"}
          hover
        >
          {checkbox && !isCheckboxPositionedRightSide && (
            <TableCell
              padding="checkbox"
            >
              <Checkbox
                icon={<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
                <path stroke="#D0D2DA" strokeLinejoin="round" strokeLinecap="round" d="m0.5,3.37546c0,-1.58807 1.28739,-2.87546 2.87546,-2.87546l17.25276,0c1.58812,0 2.87546,1.28739 2.87546,2.87546l0,17.25276c0,1.58812 -1.28734,2.87546 -2.87546,2.87546l-17.25276,0c-1.58807,0 -2.87546,-1.28734 -2.87546,-2.87546l0,-17.25276z" clipRule="evenodd" fillRule="evenodd"/>
                </svg>
                }
                checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" fill="#6200EA"/>
                <path d="M5.6001 13.3056L8.21343 17.0144C8.41456 17.3148 8.74845 17.4996 9.10983 17.5104C9.4712 17.5212 9.81553 17.3567 10.0342 17.0688L18.4001 6.48428" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                }
                checked={isSelected(item)}
                onChange={(e) => onSelectItemClick(e, item)}
                color="primary"
              />
            </TableCell>
          )}
          {Object.keys(item).map((key: any) => {
            if ((ignoredKeys || []).includes(key)) {
              return null;
            }
            if (!!styleByIndex) {
              const columnArray: Array<JSX.Element> = [];
              styleByIndex.map((styles) => {
                if (styles.columnName === key) {
                  columnArray.push(
                    <TableCell
                      id={key + index}
                    >
                      <div style={styles.style}>
                        {renderDataCells(item, key)}
                      </div>
                    </TableCell>
                  );
                }
              });
              if (
                styleByIndex.filter(
                  (styles) => styles.columnName === key
                ).length === 0
              ) {
                if (key === "permissions") {
                  columnArray.push(
                    <TableCell
                      style={bodyCellStyle}
                      key={key+'_'+index}
                    >
                      <div className="rowBodyCell">
                        {renderDataCells(item, key)}
                      </div>
                    </TableCell>
                  );
                } else {
                  if (!!showIds) {
                    columnArray.push(
                      <TableCell
                        style={bodyCellStyle}
                        key={key+'_'+index}
                      >
                        <div className="rowBodyCell">
                          {renderDataCells(item, key)}
                        </div>
                      </TableCell>
                    );
                  } else {
                    if (key !== "id") {
                      columnArray.push(
                        <TableCell  key={key+'_'+index} style={bodyCellStyle} className={classes.bodyCellStyle}>
                          {renderDataCells(item, key)}
                        </TableCell>
                      );
                    }
                  }
                }
              }
              return columnArray;
            } else {
              if (!!showIds) {
                return (
                  <TableCell
                    style={bodyCellStyle}
                    key={key+'_'+index}
                  >
                    {renderDataCells(item, key)}
                  </TableCell>
                );
              } else {
                if (key !== "id") {
                  return (
                    <TableCell style={bodyCellStyle} className={classes.bodyCellStyle} key={key}>
                      {renderDataCells(item, key)}
                    </TableCell>
                  );
                }
              }
            }
          })}
          {!!push && (
            <TableCell
              align={pushButtonAlign}
            >
              <Button
                variant="outlined"
                size={pushButtonSize}
                onClick={() => handlePushClick?.(item)}
                style={pushButtonStyle}
              >
                Send notification
              </Button>
            </TableCell>
          )}
          {!!edit && (
            <TableCell
              align={editIconAlign}
            >
              <IconButton
                className="icon-pencil"
                aria-label="edit"
                size={editIconSize}
                onClick={() => handleEditClick?.(item)}
              />
            </TableCell>
          )}
          {!!visibility && (
            <TableCell
              align={editIconAlign}
            >
              <IconButton
                size={editIconSize}
                onClick={() => handleVisibilityClick?.(item)}
              >
                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0002 0.938405C6.97699 0.887405 3.85024 3.00016 1.88449 5.16391C1.45712 5.63836 1.45712 6.35895 1.88449 6.8334C3.80749 8.9514 6.92524 11.1129 10.0002 11.0612C13.0752 11.1129 16.1937 8.9514 18.1182 6.8334C18.5456 6.35895 18.5456 5.63836 18.1182 5.16391C16.1502 3.00016 13.0235 0.887405 10.0002 0.938405Z" stroke="#595959" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8125 5.99977C12.8121 7.55298 11.5527 8.8118 9.9995 8.81152C8.4463 8.81125 7.18736 7.55198 7.1875 5.99877C7.18764 4.44557 8.4468 3.18652 10 3.18652C10.7461 3.18632 11.4617 3.48268 11.9892 4.01033C12.5167 4.53798 12.8129 5.25366 12.8125 5.99977V5.99977Z" stroke="#595959" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </IconButton>
            </TableCell>
          )}
          {!!deleteRecord && (
            <TableCell
              align={editIconAlign}
            >
              <IconButton
                className="icon-bin-circle"
                aria-label="edit"
                size={editIconSize}
                onClick={() => handleDeleteClick?.(item)}
              />
            </TableCell>
          )}
          {checkbox && isCheckboxPositionedRightSide && (
            <TableCell
              className={classes.bodyCellStyle}
              padding="checkbox"
            >
              <Checkbox
                icon={<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
                <path stroke="#D0D2DA" strokeLinejoin="round" strokeLinecap="round" d="m0.5,3.37546c0,-1.58807 1.28739,-2.87546 2.87546,-2.87546l17.25276,0c1.58812,0 2.87546,1.28739 2.87546,2.87546l0,17.25276c0,1.58812 -1.28734,2.87546 -2.87546,2.87546l-17.25276,0c-1.58807,0 -2.87546,-1.28734 -2.87546,-2.87546l0,-17.25276z" clipRule="evenodd" fillRule="evenodd"/>
                </svg>
                }
                checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" fill="#6200EA"/>
                <path d="M5.6001 13.3056L8.21343 17.0144C8.41456 17.3148 8.74845 17.4996 9.10983 17.5104C9.4712 17.5212 9.81553 17.3567 10.0342 17.0688L18.4001 6.48428" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                }
                checked={isSelected(item)}
                onChange={(e) => onSelectItemClick(e, item)}
                color="primary"
              />
            </TableCell>
          )}
          {draggable && (
            <TableCell key={"drag" + index} padding="checkbox">
              <DragHandleIcon />
            </TableCell>
          )}
        </TableRow>
        )
      )
    )
  }

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: "rgb(191, 255, 194)",
      display: "inline-table",
    }),
  });

  const DraggableComponent = (id: string, index: number) => (props: any) => {
    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            {...props}
          >
            {props.children}
          </TableRow>
        )}
      </Draggable>
    )
  }

  const DroppableComponent = (onDragEnd: (result: any, provided: any) => void) => (props: any) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="1" direction="vertical">
          {(provided) => {
            return (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...props}
              >
                {props.children}
                {provided.placeholder}
              </TableBody>
            )
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(data, result.source.index, result.destination.index);
    setData?.(items);
  };

  const renderDraggableTableBody = () => {
    return (
      <TableBody component={DroppableComponent(onDragEnd)}>
        {renderRows(data)}
      </TableBody>
    );
  };

  const renderTableBody = () => {
    return <TableBody>{renderRows(data)}</TableBody>
  };

  return (
    <div id="table-component" style={containerClassname}>
      <TableContainer component={Paper} style={tableContainerClassname}>
        <Table stickyHeader>
          <TableHead>
            <TableRow hover>
            {checkbox && !isCheckboxPositionedRightSide && (
              <TableCell padding="checkbox" style={headCellStyle}>
                <Checkbox
                  icon={<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
                  <path stroke="#D0D2DA" strokeLinejoin="round" strokeLinecap="round" d="m0.5,3.37546c0,-1.58807 1.28739,-2.87546 2.87546,-2.87546l17.25276,0c1.58812,0 2.87546,1.28739 2.87546,2.87546l0,17.25276c0,1.58812 -1.28734,2.87546 -2.87546,2.87546l-17.25276,0c-1.58807,0 -2.87546,-1.28734 -2.87546,-2.87546l0,-17.25276z" clipRule="evenodd" fillRule="evenodd"/>
                  </svg>
                  }
                  checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" fill="#6200EA"/>
                  <path d="M5.6001 13.3056L8.21343 17.0144C8.41456 17.3148 8.74845 17.4996 9.10983 17.5104C9.4712 17.5212 9.81553 17.3567 10.0342 17.0688L18.4001 6.48428" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  }
                  color="primary"
                  checked={data.length > 0 && selected.length === data.length}
                  onChange={onSelectAllClick}
                />
              </TableCell>
            )}
            {titles?.map((title: string, index: number) => (
              <TableCell
                style={isCheckboxPositionedRightSide ?
                  {
                    ...headCellStyle,
                    color: "#3C3E49",
                    fontSize: "20px",
                    backgroundColor: "#FFFFFF",
                    padding: "0 16px 30px 0",
                  } : headCellStyle}
                align={"left"}
                key={index}
              >
                <span style={{ paddingLeft: title === "Product" ? "30px" : "" }}>{title}</span>
              </TableCell>
            ))}
            {!!push && (
              <TableCell
                style={headCellStyle}
                align={"left"}
                key={titles.length}
              >
                Push
              </TableCell>
            )}
            {!!edit && (
              <TableCell
                style={headCellStyle}
                align={editIconAlign}
                key={titles.length + 1}
              >
                Edit
              </TableCell>
            )}
            {!!visibility && (
              <TableCell
                style={headCellStyle}
                align={editIconAlign}
                key={titles.length + 1}
              />
            )}
            {!!deleteRecord && (
              <TableCell
                style={headCellStyle}
                align={editIconAlign}
                key={titles.length + 2}
              >
                Delete
              </TableCell>
            )}
            {checkbox && isCheckboxPositionedRightSide && (
              <TableCell
                padding="checkbox"
                style={{
                  ...headCellStyle,
                  backgroundColor: "#FFFFFF",
                  padding: "0 0 30px 0"
                }}
              >
                <Checkbox
                  icon={<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
                  <path stroke="#D0D2DA" strokeLinejoin="round" strokeLinecap="round" d="m0.5,3.37546c0,-1.58807 1.28739,-2.87546 2.87546,-2.87546l17.25276,0c1.58812,0 2.87546,1.28739 2.87546,2.87546l0,17.25276c0,1.58812 -1.28734,2.87546 -2.87546,2.87546l-17.25276,0c-1.58807,0 -2.87546,-1.28734 -2.87546,-2.87546l0,-17.25276z" clipRule="evenodd" fillRule="evenodd"/>
                  </svg>
                  }
                  checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" fill="#6200EA"/>
                  <path d="M5.6001 13.3056L8.21343 17.0144C8.41456 17.3148 8.74845 17.4996 9.10983 17.5104C9.4712 17.5212 9.81553 17.3567 10.0342 17.0688L18.4001 6.48428" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  }
                  color="primary"
                  checked={data.length > 0 && selected.length === data.length}
                  onChange={onSelectAllClick}
                />
              </TableCell>
              )}
            {!!draggable && (
              <TableCell
                style={headCellStyle}
                align={editIconAlign}
                key="draggable"
              />
            )}
          </TableRow>
          </TableHead>
          {draggable ? renderDraggableTableBody() : renderTableBody()}
        </Table>
      </TableContainer>
    </div>
  );
};

const styles = () => createStyles({});
const useStyles = makeStyles({
  tableStyle: {
    padding: 10,
    border: "1px solid red",
  },
  bodyCellStyle: {
    "&:first-child": {
      borderLeft: "2px solid #ECEDF0",
    },
    "&:last-child": {
      borderRight: "2px solid #ECEDF0",
    },
  },
});
TableComponent.defaultProps = {
  containerClassname: {
    minHeight: 682,
  },
  tableContainerClassname: {
    boxShadow: "none",
  },
  deleteRecord: false,
  edit: false,
  activeStatus: false,
  push: false,
  checkbox: false,
  isCheckboxPositionedRightSide: false,
  showIds: true,
  downloadIconSize: "small" as "small",
  editIconSize: "small" as "small",
  editIconAlign: "center" as "center",
  pushButtonSize: "small" as "small",
  pushButtonAlign: "left" as "left",
  headCellStyle: {
    color: "#676B7E",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "normal",
    lineHeight: "22px",
    backgroundColor: "#F8F9FA",
  },
  bodyCellStyle: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#3C3E49",
  },
  activatedCellStyleTrue: {
    backgroundColor: "#EBF5F0",
    borderColor: "#B3DAC7",
    color: "#008243",
    content: 'Activated',

  },
  activatedCellStyleFalse: {
    backgroundColor: "#FFECEC",
    borderColor: "#FFD7D7",
    color: "#E00000",
    content: 'Deactivated',
  },
  descriptionContainerStyle: {},
  permissionCellStyle: {
    backgroundColor: "#F7F5FF",
    borderColor: "#BCB1F5",
    color: "#4F31E4",
    margin: 2,
    paddingLeft: 9,
    paddingRight: 9,
    lineHeight: '16px',
    fontSize: 12,
  },
  variantCellStyle: {
    backgroundColor: '#F7F5FF',
    color: '#4F31E4',
    border: '1px solid #BCB1F5',
    margin: 4,
  },
  pushButtonStyle: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: "none" as "none",
    width: "max-content",
  },
  placedStatusCellStyle: {
    backgroundColor: "#F7F5FF",
    borderColor: "#BCB1F5",
    color: "#4F31E4",
  },
  deliveredStatusCellStyle: {
    backgroundColor: "#EBF5F0",
    borderColor: "#B3DAC7",
    color: "#008243",
  },
  refundedStatusCellStyle: {
    backgroundColor: "#FFF2E7",
    borderColor: "#FFCA99",
    color: "#653508",
  },
  cancelledStatusCellStyle: {
    backgroundColor: "#FFECEC",
    borderColor: "#FFD7D7",
    color: "#E00000",
  },
  processingStatusCellStyle: {
    backgroundColor: "#FAF7E3",
    borderColor: "#FFDF3D",
    color: "#B05F07",
  },
  customerCellStyle: {
    color: "#6200EA",
    width: "max-content",
    textDecoration: "underline",
  },
  customerCellStyleWithoutClick: {
    color: "#3C3E49",
    width: "max-content",
  },
  customisedTypeCellStyle: {
    backgroundColor: "#F7F5FF",
    borderColor: "#BCB1F5",
    color: "#6200EA",
  },
  originalTypeCellStyle: {
    backgroundColor: "#F5F5F5",
    borderColor: "#AFB2C0",
    color: "#3C3E49",
  },
  draftStatusCellStyle: {
    backgroundColor: "#FFF2E7",
    borderColor: "#FFCA99",
    color: "#653508",
  },
  activeStatusCellStyle: {
    backgroundColor: "#EBF5F0",
    borderColor: "#B3DAC7",
    color: "#008243",
  },
  imageStyle: {
    width: 36,
    height: 36,
    borderRadius: 4,
    background: "#E8E8E8",
  },
};
export default withStyles(styles)(TableComponent);
